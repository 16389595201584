import React, {useContext, useState, useEffect} from "react";
import Title from '@src/component/common/Title';
import {Link} from 'react-router-dom';
import Tr from '@src/translate';
import {globalContext} from '@src/redux/reducer';
import {actions} from '@src/redux/actions';
import "./index.scss";
import Req from "@src/util/request";

export default function Footer() {
    const {store: {storeInfo = {}}, dispatch} = useContext(globalContext);
    const [helpList, setHelpList] = useState([]);
    const [copyRight, setCopyRight] = useState([]);

    const getHelpCenter = (type) => {
        Req({
            url: `/customer/v1/copywritingSetting?type=1`,
            type: "GET"
        }, res => {
            if (res) {
                console.log(res);
                setHelpList(res.data || []);
                dispatch(actions.helpCenter(res.data || []));
            }
        }, error => {
        });
    }
    const getCopyRight = () => {
        Req({
            url: `/customer/v1/webCopyright`,
            type: "GET"
        }, res => {
            if (res) {
                setCopyRight(res.data)
            }
        }, error => {
        });
    }

    useEffect(() => {
        getHelpCenter();
        getCopyRight();
    }, [])

    let num = 3;
    if(storeInfo && storeInfo.showAboutMe){
        num ++;
    }
    let itemStyle = { width: `${100 / num}%` };

    return (
        <div className="footer" id={"footer"}>
            <div className="layout">
                <div className="show-items">
                    {
                        storeInfo && storeInfo.showAboutMe ?
                            <div className="item" style={itemStyle}>
                                <Title title={Tr("common1")}/>
                                <ul>
                                    <li><Link to={'/about/about'}>{Tr("common1")}</Link></li>
                                </ul>
                            </div>
                            : null
                    }
                    <div className="item" style={itemStyle}>
                        <Title title={Tr("common2")}/>
                        <ul>
                            <li><img src="./public/img/footer/company.png" alt=""/> {storeInfo.name}</li>
                            <li><img src="./public/img/footer/phone.png" alt=""/> {storeInfo.mobile}</li>
                            {/*<li>{Tr("add14")}： {storeInfo.address && storeInfo.address.country} {storeInfo.address && storeInfo.address.city}</li>*/}
                            <li><img src="./public/img/footer/address.png" alt=""/> {storeInfo.address && storeInfo.address.address1}</li>
                        </ul>
                    </div>
                    <div className="item" style={itemStyle}>
                        <Title title={Tr("add11")}/>
                        <ul>
                            <li><Link to={'/about/register'}>{Tr("add11")}</Link></li>
                        </ul>
                    </div>
                    <div className="item" style={itemStyle}>
                        <Title title={Tr("add10")}/>
                        <ul>
                            {
                                helpList.map((item, index) => {
                                    return (
                                        <li><Link to={`/about/help${index}`}>{item.title}</Link></li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className="copyright">
                {
                    storeInfo && !storeInfo.isPaid ?
                        <div>{Tr('ximiadd269')}</div>
                        : null
                }
                {
                    copyRight && copyRight.content ?
                        <div>{copyRight && copyRight.content}</div>
                        : null
                }
            </div>
        </div>
    )
}
