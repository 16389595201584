import React from "react";
import "./index.scss";

export default function Title({ title, style={} }){
    return (
        <div className="index-title" style={{ ...style }}>
            <h1>{ title }</h1>
        </div>
    )
}
