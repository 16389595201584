// 此处设置的状态只可在同一个组件共享，用于分离页面的接口调用
import React, {useState, useCallback, useEffect, useContext} from 'react';
import Req from "@src/util/request";
import {globalContext} from "@src/redux/reducer";
import {actions} from "@src/redux/actions";

export default function useCollect(){
    const [list, setList] = useState(null);
    const [loading, setLoading] = useState(false);
    const { dispatch, store: { userInfo, collectList } } = useContext(globalContext);

    const getList = useCallback(()=>{
        setLoading('list');
        Req({
            url: '/customer/v1/collection/commodityList?limit=1000',
            type: "POST"
        }, res=>{
            if(res){
                dispatch(actions.collectList(res.data && res.data.data || []));
                setList(res.data && res.data.data );
            }
            setLoading(false);
        }, error=>{
            setLoading(false);
        });
    }, [loading]);

    const editCollect = useCallback((id)=>{
        if(!userInfo){
            location.hash = '/login';
            return ;
        }
        setLoading('edit');
        Req({
            url: '/customer/v1/collection/collectionCommodity',
            type: "POST",
            data: {
                commodity_guid: id
            }
        }, res=>{
            if(res){
                dispatch(actions.collectList([]));
                getList();
            }
            setLoading(false);
        }, error=>{
            setLoading(false);
        });
    }, [loading]);

    const isExisted = useCallback((id)=>{
        return !!(collectList && collectList.find(item=>item.guid === id))
    }, [collectList]);

    return {
        list,
        getList,
        loading,
        editCollect,
        isExisted
    }
}
