import React from 'react';
import './index.scss';

export default function ModalMask({ children, style, className }){
    return (
        <div className={ "shadow-global" + " " + className } style={{ ...(style || {}) }}>
            { children }
        </div>
    )
}
